import React from 'react'
import { Container, Row } from 'react-bootstrap'
import { graphql, StaticQuery } from 'gatsby'
import ProductCategory from '../ProductCategory'
import { getEmSize } from '../../styles/mixins'

const ProductCategoryList: React.FC = () => (
  <StaticQuery
    query={graphql`
      query {
        engine: file(relativePath: { eq: "engine.jpg" }) {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }

        transmission: file(relativePath: { eq: "transmission.jpg" }) {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }

        suspense: file(relativePath: { eq: "suspense.jpg" }) {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }

        oil: file(relativePath: { eq: "oil_filters.jpeg" }) {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }

        brakes: file(relativePath: { eq: "brakes.jpg" }) {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }

        lights: file(relativePath: { eq: "led.jpeg" }) {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <section style={{ margin: '15px 0' }}>
        <Container>
          <h3 style={{ fontSize: `${getEmSize(48)}rem` }}>Категории</h3>
          <Row className="flex-wrap">
            <ProductCategory
              img={data.engine.childImageSharp.fluid}
              title="Двигатель"
              items={['ГРМ', 'Приводные ремни', 'Кольца, поршни', 'Прокладки']}
            />
            <ProductCategory
              img={data.transmission.childImageSharp.fluid}
              title="Коробка передач"
              items={['МКПП', 'АКПП', 'Фрикционы', 'Сцепление']}
            />
            <ProductCategory
              img={data.suspense.childImageSharp.fluid}
              title="Ходовая"
              items={['Рулевые тяги', 'Амортизаторы', 'Пружины', 'Подшипники']}
            />
            <ProductCategory
              img={data.oil.childImageSharp.fluid}
              title="Масла и фильтры"
              items={['Моторные', 'Трансмиссионные', 'Масляные фильтры']}
            />
            <ProductCategory
              img={data.brakes.childImageSharp.fluid}
              title="Тормозная система"
              items={['Тормозные диски', 'Колодки', 'Цилиндры', 'Ремкомплекты']}
            />
            <ProductCategory img={data.lights.childImageSharp.fluid} title="Лампы" items={['Галогеновые', 'LED', 'Ксенон']} />
          </Row>
        </Container>
      </section>
    )}
  />
)

export default ProductCategoryList
