import React from 'react'
import { Container, Row } from 'react-bootstrap'
import { graphql, StaticQuery } from 'gatsby'
import BenefitItem from './BenefitItem'

type BenefitListProps = {
  className?: string
}

const BenefitList: React.FC<BenefitListProps> = ({ className }) => (
  <StaticQuery
    query={graphql`
      query {
        delivery: file(relativePath: { eq: "1.png" }) {
          childImageSharp {
            fixed(width: 150, height: 150) {
              ...GatsbyImageSharpFixed
            }
          }
        }

        payment: file(relativePath: { eq: "2.png" }) {
          childImageSharp {
            fixed(width: 150, height: 150) {
              ...GatsbyImageSharpFixed
            }
          }
        }

        trust: file(relativePath: { eq: "3.png" }) {
          childImageSharp {
            fixed(width: 150, height: 150) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `}
    render={data => (
      <Container as="section" style={{ padding: '100px 15px' }} className={className}>
        <Row className="justify-content-between">
          <BenefitItem title="Быстрая доставка" img={data.delivery.childImageSharp.fixed} />
          <BenefitItem title="Оплата наличными, картой" img={data.payment.childImageSharp.fixed} />
          <BenefitItem title="Работаем более 9 лет" img={data.trust.childImageSharp.fixed} />
        </Row>
      </Container>
    )}
  />
)

export default BenefitList
