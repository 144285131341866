import React from 'react'

type AddressMapProps = {
  width: string
  height: string
  frameBorder: string
}

const AddressMap: React.FC<AddressMapProps> = (data: AddressMapProps) => (
  <section>
    {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
    <iframe
      src="https://yandex.ru/map-widget/v1/?um=constructor%3Ac010e69d1e0f3a3fb663f02bade1c36cd5607b0bbae1bb2583be05fdee91e116&amp;source=constructor"
      width={data.width}
      height={data.height}
      frameBorder={data.frameBorder}
    />
  </section>
)

export default AddressMap
