import React from 'react'
import { Col, Row } from 'react-bootstrap'
import styled from '@emotion/styled'
import Img from 'gatsby-image'

const StyledProductCategory = styled(Col)``

const StyledShadowBox = styled.div`
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 44px rgba(17, 29, 48, 0.06);
  transition: ease-in-out 0.3s;
  padding: 25px;
  margin: 10px 0;
  min-height: 550px;

  ul {
    padding: 0;
    margin-top: 5px;
    list-style-type: none;

    li {
      padding: 5px 0;
    }
  }

  &:hover {
    box-shadow: 0 4px 44px rgba(17, 29, 48, 0.26);
  }
`

type ProductCategoryProps = {
  img: any
  title: string
  items: string[]
}

const ProductCategory: React.FC<ProductCategoryProps> = (data: ProductCategoryProps) => (
  <StyledProductCategory sm={6} lg={4}>
    <StyledShadowBox>
      <h6>{data.title}</h6>
      <Row className="flex-column">
        <Col>
          <Img fluid={data.img} />
        </Col>
        <Col>
          <ul>
            {data.items.map(item => (
              <li key={item.toString()}>{item}</li>
            ))}
          </ul>
        </Col>
      </Row>
    </StyledShadowBox>
  </StyledProductCategory>
)

export default ProductCategory
