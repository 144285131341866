import React from 'react'
import Img from 'gatsby-image'
import styled from '@emotion/styled'
import { Col } from 'react-bootstrap'

const StyledBenefitItem = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0;
`

type BenefitItemProps = {
  title: string
  img: any
}

const BenefitItem: React.FC<BenefitItemProps> = (data: BenefitItemProps) => (
  <StyledBenefitItem xs={12} sm={4} md={4} lg={4}>
    <Img fixed={data.img} />
    <div className="mt-3">{data.title}</div>
  </StyledBenefitItem>
)

export default BenefitItem
