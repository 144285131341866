import * as React from 'react'

import { Container } from 'react-bootstrap'
import { Link } from 'gatsby'
import Layout from '../layouts/layout'
import BenefitList from '../components/benefit/BenefitList'
import Banner from '../components/banner/Banner'
import ProductCategoryList from '../components/section/ProductCategoryList'
import ImageSection from '../components/section/ImageSection'
import AddressMap from '../components/AddressMap'
import { getEmSize } from '../styles/mixins'

const IndexPage = () => (
  <Layout>
    <div style={{ background: '#fff' }}>
      <Banner />
      <BenefitList />
      <ProductCategoryList />

      <section style={{ background: '#c91212', padding: '50px 0' }}>
        <Container>
          <h3 style={{ fontSize: `${getEmSize(48)}rem`, color: '#fff' }}>В наличии</h3>
          <h5 style={{ margin: '0', fontSize: `${getEmSize(24)}rem`, color: '#fff' }}>Расходники для иномарок</h5>
          <Link style={{ marginTop: '30px', textDecoration: 'none' }} className="btn btn-light" to="/order">
            Заказать
          </Link>
        </Container>
      </section>

      <ImageSection />
      <AddressMap width="100%" height="450" frameBorder="0" />
    </div>
  </Layout>
)

export default IndexPage
