import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import BannerBackground from './BannerBackground'
import { getEmSize } from '../../styles/mixins'

const Banner: React.FC = () => (
  <BannerBackground>
    <Container as="section">
      <Row className="align-items-md-center">
        <Col>
          <h5 style={{ fontSize: `${getEmSize(36)}rem` }}>Автозапчасти</h5>
          <h3 style={{ fontSize: `${getEmSize(36)}rem` }}>В Красноармейске</h3>
        </Col>
      </Row>
    </Container>
  </BannerBackground>
)

export default Banner
