import React from 'react'
import { graphql, StaticQuery } from 'gatsby'

import BackgroundImage from 'gatsby-background-image'

type BackgroundSectionProps = {
  className?: string
}

const BannerBackground: React.FC<BackgroundSectionProps> = ({ children, className }) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "bg3.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      return (
        <BackgroundImage style={{ padding: '200px 0' }} className={className} fadeIn={false} fluid={data.desktop.childImageSharp.fluid}>
          {children}
        </BackgroundImage>
      )
    }}
  />
)

export default BannerBackground
