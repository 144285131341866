import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Img from 'gatsby-image'
import { graphql, StaticQuery } from 'gatsby'
import { getEmSize } from '../../styles/mixins'

const ImageSection: React.FC = () => (
  <StaticQuery
    query={graphql`
      query {
        img: file(relativePath: { eq: "delivery_bg.jpg" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => (
      <section style={{ padding: '50px 0' }}>
        <Container fluid>
          <Row>
            <Col xs={12} sm={6} md={6}>
              <Img fluid={data.img.childImageSharp.fluid} />
            </Col>
            <Col xs={12} sm={6} md={6}>
              <h3 style={{ fontSize: `${getEmSize(48)}rem` }}>От 1 дня</h3>
              <h5 style={{ fontSize: `${getEmSize(24)}rem` }}>Привозим под заказ</h5>
            </Col>
          </Row>
        </Container>
      </section>
    )}
  />
)

export default ImageSection
